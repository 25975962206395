import { is } from './utils';

const browserSupportsLocalStorage = () => typeof window.localStorage !== 'undefined';

const getCookieItem = (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return parts.pop().split(';').shift();
	}

	return undefined;
};

const setCookieItem = (name, value, date) => {
	if (is(date) !== 'date') {
		return undefined;
	}

	document.cookie = `${name}=${value}; path=/; expires=${date.toUTCString()}; sameSite=Strict`;

	return undefined;
};

const storeCookieItem = (name, value) => {
	const secondsInYear = 365 * 24 * 60 * 60;
	document.cookie = `${name}=${value}; path=/; max-age=${secondsInYear}; sameSite=Strict`;
};

const removeCookieItem = (name) => {
	const date = new Date();
	// remove by setting to the year before the current one
	date.setFullYear(date.getFullYear() - 1);
	setCookieItem(name, '', date);
};

const getItem = (key) => {
	if (browserSupportsLocalStorage()) {
		return localStorage.getItem(key);
	}

	return getCookieItem(key);
};

const storeItem = (key, value) => {
	if (browserSupportsLocalStorage()) {
		localStorage.setItem(key, value);
		return;
	}

	storeCookieItem(key, value);
};

const deleteItem = (key) => {
	if (browserSupportsLocalStorage()) {
		localStorage.removeItem(key);
		return;
	}

	removeCookieItem(key);
};

export {
	getCookieItem,
	setCookieItem,
	storeCookieItem,
	removeCookieItem,
	getItem,
	storeItem,
	deleteItem,
};
