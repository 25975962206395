/* global SlickLoader */
import axios from 'axios';
import _get from 'lodash/get';
import config from './config';
import * as browserStorage from './lib/browserStorage';
import { jwtTokenIsNotValid, jwtTokenIsStillValid } from './lib/utils';
// import './libraries/slick-loader.min';

(function (win, dom) {
	const authToken = browserStorage.getItem(config.authTokenKey);
	const user = JSON.parse(browserStorage.getItem(config.userKey));
	const restNonceElement = dom.querySelector('[data-rest-nonce]');
	const restNonce = restNonceElement ? restNonceElement.getAttribute('data-rest-nonce') : null;
	const restBaseElement = dom.querySelector('[data-rest-base]');
	const restBase = restBaseElement ? restBaseElement.getAttribute('data-rest-base') : null;
	const axiosHeaders = {
		Accept: 'application/json',
	};
	if (authToken) {
		axiosHeaders.Authorization = `Bearer ${authToken}`;
	}

	if (restNonce) {
		axiosHeaders['X-WP-Nonce'] = restNonce;
	}

	const updateWishlistProductCountInDOM = function (response, updateDOMCount = true) {
		const { data } = response;
		const wishlistId = _get(data, 'id', null);
		if (!wishlistId) {
			return;
		}

		const { products } = data;
		if (Array.isArray(products)) {
			// check if any of the products is in the carousel, is in the wishlist and highlight it
			products.forEach(({ productId }) => {
				const productElement = dom.querySelector(`[data-product="${productId}"]`);
				// eslint-disable-next-line
                productElement?.classList.add('active');
			});
		}

		browserStorage.storeItem(config.wishlistKey, JSON.stringify(data));
		if (!updateDOMCount) {
			return;
		}

		const productsCount = _get(products, 'length', 0);
		[].slice.call(dom.querySelectorAll('[data-user-fave]')).forEach((element) => {
			const productCountElement = element.querySelector('[data-product-count]');
			if (!productCountElement) {
				return;
			}

			productCountElement.textContent = productsCount;
		});
	};

	const fetchCurrentUserWishlist = function () {
		const wishlist = JSON.parse(browserStorage.getItem(config.wishlistKey));
		// if wishlist exists
		if (wishlist && wishlist.id) {
			console.log();
			const url = `${restBase}/users/wishlist/${wishlist.id}`;
			axios
				.get(url, { headers: { ...axiosHeaders }, params: { t: Date.now() } })
				.then(updateWishlistProductCountInDOM)
				.catch(console.error);

			return;
		}

		// if wishlist does not and if user is not logged, do nothing
		if (jwtTokenIsNotValid(authToken)) {
			return;
		}

		const url = `${restBase}/users/${user.id}/wishlist`;
		axios
			.get(url, { headers: { ...axiosHeaders }, params: { t: Date.now() } })
			.then(updateWishlistProductCountInDOM)
			.catch(console.error);
	};

	const updateCartProductCountInDOM = function (response) {
		const { data } = response;
		if (!data) {
			return;
		}

		[].slice.call(dom.querySelectorAll('[data-user-cart]')).forEach((element) => {
			const productCountElement = element.querySelector('[data-product-count]');
			if (!productCountElement) {
				return;
			}

			productCountElement.textContent = _get(data, 'cart.product_count', 0);
		});
	};

	const fetchCurrentUserCart = function () {
		const url = `${restBase}/users/${user.id}/cart`;
		axios
			.get(url, { headers: { ...axiosHeaders }, params: { t: Date.now() } })
			.then(updateCartProductCountInDOM)
			.catch(updateCartProductCountInDOM);
	};

	dom.addEventListener('click', function (event) {
		let { target: element } = event;
		const { tagName } = element;
		const tagNameInLowercase = tagName.toLocaleLowerCase();
		const acceptableTags = [
			'a',
			'button',
			'svg',
			'path',
			'div',
			'defs',
			'g',
			'circle',
			'span',
			'img',
			'rect',
			'p',
			'clippath',
		];
		if (!acceptableTags.includes(tagNameInLowercase)) {
			return;
		}

		const neededTags = ['a', 'button'];
		while (element && !neededTags.includes(element.tagName.toLocaleLowerCase())) {
			element = element.parentElement;
		}

		if (!element) {
			return;
		}

		const handleCartAndWishlistLink = function (event, callback) {
			const productsBaseElement = dom.querySelector('[data-products-base]');
			if (!productsBaseElement) {
				return;
			}

			event.preventDefault();
			const url = productsBaseElement.getAttribute('data-products-base');
			const queryArgs = {};
			if (jwtTokenIsStillValid(authToken)) {
				queryArgs.id = _get(user, 'id');
				queryArgs.email = _get(user, 'email');
			}

			const _url = callback(url, queryArgs);
			win.open(_url, '_blank');
		};

		if (element.hasAttribute('data-user-cart')) {
			handleCartAndWishlistLink(event, function (url, queryArgs) {
				const queryString = Object.entries(queryArgs)
					.filter((entry) => entry.at(1))
					.map(([key, value]) => `${key}=${value}`)
					.join('&');

				return `${url}/cart${queryString ? `?${queryString}` : ''}`;
			});

			return;
		}

		if (
			element.hasAttribute('data-user-fave') ||
			(element.hasAttribute('data-view') && element.getAttribute('data-view') === 'wishlist')
		) {
			handleCartAndWishlistLink(event, function (url, queryArgs) {
				const wishlist = JSON.parse(browserStorage.getItem(config.wishlistKey));
				queryArgs.wishlistId = _get(wishlist, 'id', null);
				const queryString = Object.entries(queryArgs)
					.filter((entry) => entry.at(1))
					.map(([key, value]) => `${key}=${value}`)
					.join('&');

				return `${url}/wishlist?${queryString}`;
			});

			return;
		}

		// favouriting
		if (element.hasAttribute('data-product')) {
			event.preventDefault();
			const updateProductCountInDOM = function (productCount) {
				[].slice.call(dom.querySelectorAll('[data-user-fave]')).forEach((element) => {
					const productCountElement = element.querySelector('[data-product-count]');
					if (!productCountElement) {
						return;
					}

					productCountElement.textContent = productCount;
				});
			};

			const currentProductCountElement = dom.querySelector(
				'[data-user-fave] [data-product-count]',
			);
			const currentProductCount = parseInt(currentProductCountElement?.textContent, 10) || 0;
			const productId = element.getAttribute('data-product');
			const wishlist = JSON.parse(browserStorage.getItem(config.wishlistKey));

			// update wishlist
			if (wishlist && wishlist.id) {
				element.classList.toggle('active');
				let product = { productId };
				const { products } = wishlist;
				if (Array.isArray(products)) {
					const productIndex = products.findIndex((p) => p.productId === productId);
					// product exists, remove it
					if (productIndex >= 0) {
						products.splice(productIndex, 1);
						updateProductCountInDOM(currentProductCount - 1);
					} else {
						// product does not exist, add it
						product = {
							...product,
							modelId: 0,
							variantId: 0,
						};
						wishlist.products = [...products, product];
						updateProductCountInDOM(currentProductCount + 1);
					}
				}

				SlickLoader.enable();
				const url = `${restBase}/users/wishlist/${wishlist.id}`;
				axios
					.put(
						url,
						{ wishlist },
						{ headers: { ...axiosHeaders, 'Content-Type': 'application/json' } },
					)
					.then((response) => {
						updateWishlistProductCountInDOM(response, false);
						SlickLoader.disable();
					})
					.catch(() => {
						element.classList.remove('active');
						updateProductCountInDOM(currentProductCount);
						SlickLoader.disable();
					});

				return;
			}

			// create wishlist
			updateProductCountInDOM(1);
			SlickLoader.enable();
			const url = `${restBase}/users/wishlist`;
			const date = new Date();
			const product = { modelId: 0, variantId: 0, productId };
			const wishlistData = {
				products: [product],
				name: _get(user, 'display_name', 'Anonymous'),
				email: _get(user, 'email', `a.${date.getTime()}.z@example.com`),
				analyticsUserId: '',
				emailOptIn: false,
			};
			const user_id = _get(user, 'id', null);
			axios
				.post(
					url,
					{ wishlist: wishlistData, user_id },
					{ headers: { ...axiosHeaders, 'Content-Type': 'application/json' } },
				)
				.then((response) => {
					updateWishlistProductCountInDOM(response, false);
					SlickLoader.disable();
				})
				.catch(() => {
					element.classList.remove('active');
					updateProductCountInDOM(currentProductCount);
					SlickLoader.disable();
				});
		}
	});

	win.addEventListener('load', function () {
		fetchCurrentUserWishlist();
		if (jwtTokenIsNotValid(authToken)) {
			return;
		}

		if (!restBase) {
			return;
		}

		fetchCurrentUserCart();
	});
})(window, window.document);
