import jwt_decode from 'jwt-decode';

const is = (value) => Object.prototype.toString.call(value).slice(8, -1).toLowerCase();

const removeChildNodes = (element) => {
	if (!element) {
		return;
	}

	if (!(element instanceof HTMLElement)) {
		return;
	}

	if (!element.hasChildNodes()) {
		return;
	}

	while (element.firstChild) {
		element.removeChild(element.lastChild);
	}
};

const replaceSpecialCharacters = function (value) {
	if (!value) {
		return value;
	}

	let s = String(value);
	const specialCharacters = {
		'&amp;': '&',
		'&#8217;': "'",
		'&#8211;': '-',
		'&#8212;': '-',
		'&#039;': "'",
		'&#x27;': "'",
		'&quot;': '"',
		'&#034;': '"',
		'&#x22;': '"',
		'&#038;': '&',
		'&#x26;': '&',
	};

	for (const [char, replacement] of Object.entries(specialCharacters)) {
		s = s.replace(new RegExp(char, 'g'), replacement);
	}

	return s;
};

const jwtTokenIsStillValid = function (token) {
	if (!token) {
		return false;
	}

	const tokenRegex = /^(?:[\w-]*\.){2}[\w-]*$/;
	if (!tokenRegex.test(token)) {
		return false;
	}

	const jwtTokenData = jwt_decode(token);
	const currentDatetime = new Date();
	const { exp: tokenExpirationUnixTimestamp } = jwtTokenData;
	if (!(tokenExpirationUnixTimestamp && Number.isInteger(tokenExpirationUnixTimestamp))) {
		return false;
	}

	const jwtTokenExpiration = new Date(tokenExpirationUnixTimestamp * 1000);

	return currentDatetime < jwtTokenExpiration;
};

const jwtTokenIsNotValid = function (token) {
	return !jwtTokenIsStillValid(token);
};

const getWPMLCurrentLanguage = function (defaultLanguage = 'en') {
	const lang = document.querySelector('html').getAttribute('lang');
	if (!lang) {
		return defaultLanguage;
	}

	const wpmlLanguage = lang.substring(0, lang.indexOf('-'));
	console.log({ wpmlLanguage });

	return wpmlLanguage.trim().toLocaleLowerCase();
};

export {
	is,
	removeChildNodes,
	replaceSpecialCharacters,
	jwtTokenIsStillValid,
	jwtTokenIsNotValid,
	getWPMLCurrentLanguage,
};
